import React from 'react';
import { FaBars } from 'react-icons/fa';
import i18next from 'i18next';
import cookies from 'js-cookie';

import { Link } from 'react-router-dom';

import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai';

import { ReactComponent as MainLogo } from '../../../assets/smartHireLogo.svg';
import { useWindowSize } from 'react-use';
import { useTranslation } from 'react-i18next';

export default function Navbar({ featureRef, clientRef }) {
  const { width } = useWindowSize();
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation();

  const [navbarOpen, setNavbarOpen] = React.useState(false);
  // const [activeLink, setActiveLink] = React.useState('home');

  // const handleClick = (e) => {
  //   e.preventDefault();
  //   const target = e.target.getAttribute('href');
  //   alert(target);
  //   if (target === '#services') {
  //     const location = document.querySelector(target).offsetTop;

  //     window.scrollTo({
  //       left: 0,
  //       top: location - 64,
  //     });
  //     setActiveLink('features');
  //   }
  // };

  const renderArrowFn = () =>
    currentLanguageCode === 'ar' ? (
      <AiOutlineLeft
        className={`${navbarOpen ? 'text-midnightBlue' : 'hidden'} `}
      />
    ) : (
      <AiOutlineRight
        className={`${navbarOpen ? 'text-midnightBlue' : 'hidden'} `}
      />
    );

  return (
    <>
      <nav className='relative flex flex-wrap items-center justify-between px-2 py-4 bg-white border-gradient-bottom'>
        <div className='container px-4 mx-auto flex flex-wrap items-center justify-between'>
          <div className='w-full relative flex justify-between xl:w-auto xl:static xl:block xl:justify-start'>
            <MainLogo className={`${currentLanguageCode==='ar'?"w-32 h-16":"w-40 h-16"} `} />
            <button
              className='text-rose cursor-pointer text-2xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block xl:hidden outline-none focus:outline-none'
              type='button'
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <FaBars />
            </button>
          </div>
          <div
            className={
              'xl:flex flex-grow items-center' +
              (navbarOpen ? ' block' : ' hidden')
            }
            id='example-navbar-danger'
          >
            <ul className='flex flex-col xl:flex-row list-none xl:ml-auto mr-0 xl:mx-auto '>
              <Link to='/'>
                <li
                  className={`mx-4 xl:mx-6 text-lg cursor-pointer text-midnightBlue  ${
                    navbarOpen
                      ? '  flex mb-2 mt-5 text-xl justify-between  '
                      : ''
                  } ${
                    window.location.pathname === '/' && !navbarOpen
                      ? 'border-b-2 border-gradient'
                      : ''
                  }`}
                >
                  {t('navbar_home')}
                  {renderArrowFn()}
                </li>
              </Link>
              <hr
                className={`${
                  navbarOpen
                    ? 'flex w-11/12 ml-6  border-t-1 border-gray-300 mb-5 '
                    : 'hidden'
                }`}
              />

              <Link to='/features'>
                <li
                  onClick={featureRef ? featureRef : () => {}}
                  className={`mx-4 xl:mx-6 text-lg cursor-pointer text-midnightBlue  ${
                    navbarOpen
                      ? '  flex mb-2 mt-5 text-xl justify-between  '
                      : ''
                  } ${
                    window.location.pathname.includes('features') && !navbarOpen
                      ? 'border-b-2 border-gradient'
                      : ''
                  }`}
                >
                  {t('navbar_features')}
                  {renderArrowFn()}
                </li>
              </Link>

              {/* <hr
                className={`${
                  navbarOpen
                    ? 'flex w-11/12 ml-6  border-t-1 border-gray-300 mb-5'
                    : 'hidden'
                }`}
              /> */}

              {/* <Link to='/clients'>
                <li
                  onClick={clientRef ? clientRef : () => {}}
                  className={`mx-4 xl:mx-6 text-lg cursor-pointer text-midnightBlue  ${
                    navbarOpen
                      ? '  flex mb-2 mt-5 text-xl justify-between  '
                      : ''
                  } ${
                    window.location.pathname.includes('clients') && !navbarOpen
                      ? 'border-b-2 border-gradient'
                      : ''
                  }`}
                >
                  Clients
                  {renderArrowFn()}
                </li>
              </Link> */}

              <hr
                className={`${
                  navbarOpen
                    ? 'flex w-11/12 ml-6  border-t-1 border-gray-300 mb-5'
                    : 'hidden'
                }`}
              />

              <Link to='/pricing-plans'>
                <li
                  className={`mx-4 xl:mx-6 text-lg cursor-pointer text-midnightBlue  ${
                    navbarOpen && width > 700
                      ? '  flex mb-2 mt-5 text-xl justify-between'
                      : navbarOpen && width < 700
                      ? 'hidden'
                      : ''
                  } ${
                    window.location.pathname.includes('pricing') && !navbarOpen
                      ? 'border-b-2 border-gradient'
                      : ''
                  }`}
                >
                  {t('navbar_pricing')}
                  {renderArrowFn()}
                </li>
              </Link>
              <hr
                className={`${
                  navbarOpen && width > 700
                    ? 'flex w-11/12 ml-6  border-t-1 border-gray-300 mb-5 '
                    : navbarOpen && width < 700
                    ? 'hidden'
                    : 'hidden'
                }`}
              />
              <Link to='/demo'>
                <li
                  className={`mx-4 xl:mx-6 text-lg cursor-pointer text-midnightBlue  ${
                    navbarOpen
                      ? '  flex mb-2 mt-5 text-xl justify-between   '
                      : ''
                  } ${
                    window.location.pathname.includes('demo') && !navbarOpen
                      ? 'border-b-2 border-gradient'
                      : ''
                  }`}
                >
                  {t('navbar_schedule_a_demo')}
                  {renderArrowFn()}
                </li>
              </Link>
              {/* <Link to='/blog'>
                <li
                  className={`mx-4 xl:mx-6 text-lg cursor-pointer  text-midnightBlue ${
                    navbarOpen
                      ? '  flex mb-2 mt-5 text-xl justify-between   '
                      : ''
                  } ${
                    window.location.pathname.includes('blog') && !navbarOpen
                      ? 'border-b-2 border-gradient'
                      : ''
                  }`}
                >
                  Blog
                  <AiOutlineRight
                    className={`${navbarOpen ? 'text-orange' : 'hidden'}`}
                  />
                </li>
              </Link> */}
              <hr
                className={`${
                  navbarOpen
                    ? 'flex w-11/12 ml-6  border-t-1 border-gray-300 mb-5 '
                    : 'hidden'
                }`}
              />

              {/* <Link to='/resources'> */}
              <Link to='/contact'>
                <li
                  className={`mx-4 xl:mx-6 text-lg cursor-pointer text-midnightBlue  ${
                    navbarOpen
                      ? '  flex mb-2 mt-5 text-xl justify-between  '
                      : ''
                  } ${
                    window.location.pathname.includes('contact') && !navbarOpen
                      ? 'border-b-2 border-gradient '
                      : ''
                  }`}
                >
                  {t('navbar_contact')}
                  {renderArrowFn()}
                </li>
              </Link>
              <hr
                className={`${
                  navbarOpen
                    ? 'flex w-11/12 ml-6  border-t-1 border-gray-300 mb-5 '
                    : 'hidden'
                }`}
              />
              <a
                href='https://app.smart-hire.ai/auth/login'
                target='_blank'
                rel='noopener noreferrer'
              >
                <li
                  className={`mx-4 xl:mx-6 text-lg cursor-pointer text-midnightBlue  ${
                    navbarOpen
                      ? '  flex mb-2 mt-5 text-xl justify-between  '
                      : ''
                  } ${
                    window.location.pathname.includes('login') && !navbarOpen
                      ? 'border-b-2 border-gradient'
                      : ''
                  }`}
                >
                  {t('navbar_login')}
                  {renderArrowFn()}
                </li>
              </a>

              <hr
                className={`${
                  navbarOpen
                    ? 'flex w-11/12 ml-6  border-t-1 border-gray-300 mb-5 '
                    : 'hidden'
                }`}
              />
            </ul>
            <div
              className={`${
                currentLanguageCode === 'ar' ? 'ml-4' : 'mr-4'
              } mb-4 flex space-x-4 items-center `}
            >
              <button
                type='button'
                onClick={() => {
                  i18next.changeLanguage('en');
                  localStorage.setItem('dir', JSON.stringify('ltr'));
                }}
                className={`${currentLanguageCode === 'ar' ? 'ml-4' : ''}`}
              >
                <p
                  className={`${
                    currentLanguageCode === 'en'
                      ? 'text-purple'
                      : 'text-custome_gray'
                  } `}
                >
                  En
                </p>
              </button>
              <button
                className={`flex items-start mb-1`}
                type='button'
                onClick={() => {
                  i18next.changeLanguage('ar');
                  localStorage.setItem('dir', JSON.stringify('rtl'));
                }}
              >
                <p
                  className={`w-full rounded text-xl mt-1 ${
                    currentLanguageCode === 'ar'
                      ? 'text-purple'
                      : 'text-custome_gray'
                  } `}
                >
                  العربية
                </p>
              </button>
            </div>
            <div className='flex flex-col'>
              <a
                href='https://app.smart-hire.ai/auth/register?trial=true'
                target='_blank'
                rel='noopener noreferrer'
              >
                <button className='py-2 w-full  bg-purple rounded text-white text-md'>
                  {t('navbar_try_for_free')}
                </button>
              </a>
              <small className='mt-0.5 text-gray-400'>
                {t('navbar_no_card_required')}
              </small>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
