import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

import Loader from './Loader/index';

import { ReactComponent as MainLogo } from '../../../assets/smartHireLogo.svg';
// import { ReactComponent as GetInTouch } from '../../../assets/images/getInTouch.svg';
import Vector3 from '../../../assets/images/Vector3.png';
import Vector2 from '../../../assets/images/Vector2.png';
import Group12108 from '../../../assets/images/Group12108.png';
import Vector from '../../../assets/images/Vector.png';
import Vector1 from '../../../assets/images/Vector1.png';
// import saudhiFlag from '../../../assets/images/contact/saudhiFlag.png';
// import {ReactComponent as SaudhiFlag} from "../../../assets/flag.svg";
// import Vector4 from '../../../assets/images/Vector4.png';
// import image63 from '../../../assets/images/image63.png';
// import image64 from '../../../assets/images/image64.png';
// import image65 from '../../../assets/images/image65.png';
// import image66 from '../../../assets/images/image66.png';

const Footer = ({ featureRef }) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const currentLanguageCode = cookies.get('i18next');
  const { t } = useTranslation();

  const location = useLocation();

  const subscribeFunction = async (event) => {
    event?.preventDefault();
    if (email.length > 0) {
      setIsLoading(true);
      // POST request using fetch with set headers
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Authorization: 'Bearer my-token',
        },
        body: JSON.stringify({ email: email, for_app: false }),
      };

      const response = await fetch(
        'https://app.smart-hire.ai/Profile/SubsribeAPI/',
        requestOptions
      );
      setIsLoading(false);
      setEmail('');
      // eslint-disable-next-line
      const data = await response.json();
      // console.log(data);
    }
  };

  return (
    <div className='bg-white'>
      {/* Section 03 */}
      <div
        className={`${
          currentLanguageCode === 'ar' ? 'pr-4 md:pr-0' : 'pl-4 md:pl-0'
        } bg-white pt-16 md:pt-8 pb-6 lg:w-11/12 mx-auto `}
      >
        <div className='flex md:flex-col lg:flex-row flex-wrap md:items-center lg:justify-between w-full'>
          <div
            className={`${
              currentLanguageCode === 'ar'
                ? 'lg:pl-4 lg:border-l-2'
                : 'lg:pr-4 lg:border-r-2'
            } lg:w-2/6 w-4/5  border-rose md:px-5  `}
          >
            {/* <MainLogo className='w-32 h-20' /> */}
            <div
              className={`${
                location?.pathname === '/contact' ? 'hidden lg:flex' : 'md:flex'
              }  md:items-center w-full md:justify-between lg:justify-start`}
            >
              <div
                className={`${
                  currentLanguageCode === 'ar' ? 'pl-6' : 'pr-6'
                }  `}
              >
                <MainLogo className='w-28 h-20' />
              </div>
              {/* <hr className='w-0.5 h-44 pt-2 bg-rose mx-3 bg-opacity-40 hidden md:block' /> */}
              <div>
                <div className='text-15px text-737373 font-medium mb-6 lg:mb-0'>
                  <p className='font-medium pt-2'>
                    {t('footer_smart_hire')}
                    <br />
                    {t('footer_address_l1')}
                    <br />
                    {t('footer_address_l2')}
                    <br />
                    {t('footer_address_l3')}
                  </p>
                  <div className='block md:hidden lg:block'>
                    <p className='pt-3 text-737373'>info@smart-hire.ai</p>
                    <p
                      className={`${
                        currentLanguageCode === 'ar' ? 'text-right' : ''
                      } font-sans text-737373 `}
                      dir='ltr'
                      lang='en'
                    >
                      +966 11 288 03 22
                    </p>
                  </div>
                </div>
              </div>
              <div className='hidden md:block lg:hidden'>
                <p className='pt-3 text-737373'>info@smart-hire.ai</p>
                <p
                  className={`${
                    currentLanguageCode === 'ar' ? 'text-right' : ''
                  } font-sans text-737373 `}
                  dir='ltr'
                  lang='en'
                >
                  +966 11 288 03 22
                </p>
              </div>
            </div>
          </div>
          <div
            className={`${
              currentLanguageCode === 'ar' ? 'pr-30 lg:pr-20' : 'pl-30 lg:pl-20'
            } w-4/5 lg:w-4/6 block md:flex justify-between  md:px-5 lg:px-0`}
          >
            <div
              className={`${
                currentLanguageCode === 'ar' ? 'md:text-right' : 'md:text-left'
              }`}
            >
              <h2 className='font-bold text-2xl md:text-3xl text-midnightBlue'>
                {t('footer_sub_product')}
                <span className='text-rose'>.</span>
              </h2>
              <ul className='flex flex-col mt-2'>
                <Link to='/features'>
                  <li
                    onClick={featureRef ? featureRef : () => {}}
                    className={`text-lg my-1 text-custome_gray cursor-pointer`}
                  >
                    {t('footer_sub_product_features')}
                  </li>
                </Link>
                {/* <Link to='/product-page'> */}
                <li className={`text-lg my-1 text-custome_gray opacity-50`}>
                  {t('footer_product_updates')}
                </li>
                {/* </Link> */}
                <li className='text-lg my-1 text-custome_gray opacity-50'>
                  {t('referral_program')}
                </li>
                {/* <Link to='/product-page'> */}
                <li className='text-lg my-1 text-custome_gray opacity-50'>
                  {t('footer_sub_resources')}
                </li>
                {/* </Link> */}
                {/* <li className='text-lg my-1 text-custome_gray opacity-50'>
                  GDPR
                </li> */}
              </ul>
            </div>
            <div className='hidden md:block'>
              <h2 className='font-bold text-2xl md:text-3xl text-midnightBlue'>
                {t('footer_sub_resources')}
                <span className='text-rose'>.</span>
              </h2>
              <ul className='flex flex-col mt-2'>
                <Link to='/clients'>
                  <li className='text-lg my-1 text-custome_gray'>
                    {t('testimonials')}
                  </li>
                </Link>
                <li className='text-lg my-1 text-custome_gray opacity-50'>
                  {t('webinars')}
                </li>
              </ul>
            </div>
            <div className='hidden md:block'>
              <h2 className='font-bold text-3xl text-midnightBlue'>
                {t('footer_sub_know_more')}
                <span className='text-rose'>.</span>
              </h2>
              <ul className='flex flex-col mt-2'>
                <li className='text-lg my-2 flex items-center text-custome_gray pb-1'>
                  <img src={Vector2} alt='' />
                  {/* <OwnImage
                    src='/images/Vector (2).png'
                    layout='fill'
                    width={20}
                    alt=''
                  /> */}{' '}
                  <p
                    className={`${
                      currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                    }`}
                  >
                    info@smart-hire.ai
                  </p>
                </li>
                <li className='text-lg my-1 flex items-center text-custome_gray '>
                  <a
                    href='https://www.youtube.com/channel/UCXhHovAAwYvd2BxduHLxLaQ'
                    target='_black'
                    className={`${
                      currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                    }`}
                  >
                    <img src={Group12108} alt='' />
                    {/* <OwnImage
                      layout='fill'
                      width={20}
                      src='/images/Group 12108.png'
                      alt=''
                    /> */}
                  </a>
                  <a
                    href='https://twitter.com/SmartHire_ai'
                    target='_black'
                    className='mx-2'
                  >
                    <img src={Vector} alt='' />
                    {/* <OwnImage
                      layout='fill'
                      width={20}
                      src='/images/Vector.png'
                      alt=''
                    /> */}
                  </a>
                  <a
                    href='https://www.linkedin.com/company/smarthire-ai/'
                    target='_black'
                    className={`${
                      currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                    }`}
                  >
                    <img src={Vector1} alt='' />
                    {/* <OwnImage
                      layout='fill'
                      width={20}
                      src='/images/Vector (1).png'
                      alt=''
                    /> */}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* subscribe for large screens */}
          <div className='hidden md:flex lg:justify-end w-4/5 mt-6 lg:mt-0 lg:w-full items-center'>
            <div>
              <form onSubmit={subscribeFunction}>
                <div className='shadow-xl my-0 lg:my-3 flex w-60 mb-4'>
                  <input
                    type='email'
                    name=''
                    value={email}
                    className='py-2 px-0 md:px-4 w-44'
                    placeholder={t('footer_input_place_holder')}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <button
                    type='submit'
                    className='bg-purple text-sm w-36 ml-auto text-white px-1.5 py-1 md:py-2.5 md:px-4 lg:px-5 z-50 whitespace-nowrap'
                  >
                    {isLoading ? (
                      <span className='flex justify-center'>
                        <Loader />
                      </span>
                    ) : (
                      t('footer_input_lets_get_started')
                    )}
                  </button>
                </div>
              </form>
              <ul className='block md:flex justify-between'>
                <li className='text-sm flex items-center text-383838 py-1 md:py-0'>
                  <img
                    src={Vector3}
                    className={`${
                      currentLanguageCode === 'ar' ? 'ml-1' : 'mr-1'
                    }`}
                    alt=''
                  />
                  {t('quick_easy')}
                </li>
                <li className='text-sm flex items-center text-383838 py-1 md:py-0 px-2'>
                  <img
                    src={Vector3}
                    className={`${
                      currentLanguageCode === 'ar' ? 'ml-1' : 'mr-1'
                    }`}
                    alt=''
                  />
                  {t('personality_based')}
                </li>
                <li className='text-sm flex items-center text-383838 py-1 md:py-0'>
                  <img
                    src={Vector3}
                    className={`${
                      currentLanguageCode === 'ar' ? 'ml-1' : 'mr-1'
                    }`}
                    alt=''
                  />
                  {t('customizable')}
                </li>
              </ul>
            </div>
          </div>
          {/* mobile start */}
          <div className='w-3/4 md:hidden'>
            <h2 className='font-bold  text-2xl pt-6 md:pt-0'>
              {t('footer_sub_resources')}
              <span className='text-orange'>.</span>
            </h2>
            <ul className='flex flex-col mt-2'>
              {/* <li className='text-lg my-1 text-custome_gray'>Case studies</li> */}

              <Link to='/testimonials'>
                <li className='text-lg my-1 text-custome_gray'>
                  {t('testimonials')}
                </li>
              </Link>
              <li className='text-lg my-1 text-custome_gray opacity-50'>
                {t('webinars')}
              </li>
            </ul>
          </div>
          <div className='md:hidden'>
            <h2 className='font-bold text-2xl pt-6 '>
              {t('footer_sub_know_more')}
              <span className='text-orange'>.</span>
            </h2>
            <ul className='flex flex-col mt-2'>
              <li className='text-lg my-2 flex items-center text-custome_gray'>
                <img src={Vector2} alt='' />
                <p
                  className={`${
                    currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                  }`}
                >
                  info@smart-hire.ai
                </p>
              </li>
              <li className='text-lg my-1 flex justify-start items-center text-custome_gray'>
                <a
                  href='https://www.youtube.com/channel/UCXhHovAAwYvd2BxduHLxLaQ'
                  target='_black'
                  className={`${
                    currentLanguageCode === 'ar' ? 'ml-2' : 'mr-2'
                  }`}
                >
                  <img src={Group12108} alt='' />
                </a>
                <a
                  href=' https://twitter.com/SmartHire_ai'
                  target='_black'
                  className='mx-2'
                >
                  <img src={Vector} alt='' />
                </a>
                <a
                  href='https://www.linkedin.com/company/smarthire-ai/'
                  target='_black'
                  className={`${
                    currentLanguageCode === 'ar' ? 'mr-2' : 'ml-2'
                  }`}
                >
                  <img src={Vector1} alt='' />
                </a>
              </li>
            </ul>
          </div>
          {/* subscribe for mobile screens */}
          <div className='md:hidden lg:justify-end w-3/4 mt-8 items-center'>
            <div>
              <form onSubmit={subscribeFunction}>
                <div className='shadow-xl my-0 lg:my-3 flex w-60 mb-4'>
                  <input
                    type='email'
                    name=''
                    value={email}
                    className='py-2 px-0 md:px-4 w-44'
                    placeholder={t('footer_input_place_holder')}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <button
                    type='submit'
                    className='bg-purple text-sm w-36 ml-auto text-white px-1.5 py-1 md:py-2.5 md:px-4 lg:px-5 z-50 whitespace-nowrap'
                  >
                    {isLoading ? (
                      <span className='flex justify-center'>
                        <Loader />
                      </span>
                    ) : (
                      t('footer_input_lets_get_started')
                    )}
                  </button>
                </div>
              </form>
              <ul className='block md:flex justify-between'>
                <li className='text-sm flex items-center text-383838 py-1 md:py-0'>
                  <img
                    src={Vector3}
                    className={`${
                      currentLanguageCode === 'ar' ? 'ml-1' : 'mr-1'
                    }`}
                    alt=''
                  />
                  {t('quick_easy')}
                </li>
                <li className='text-sm flex items-center text-383838 py-1 md:py-0'>
                  <img
                    src={Vector3}
                    className={`${
                      currentLanguageCode === 'ar' ? 'ml-1' : 'mr-1'
                    }`}
                    alt=''
                  />
                  {t('personality_based')}
                </li>
                <li className='text-sm flex items-center text-383838 py-1 md:py-0'>
                  <img
                    src={Vector3}
                    className={`${
                      currentLanguageCode === 'ar' ? 'ml-1' : 'mr-1'
                    }`}
                    alt=''
                  />
                  {t('customizable')}
                </li>
              </ul>
            </div>
          </div>
          {/* mobile end */}
        </div>
        {/* <div className='flex justify-center'>
          <div className='flex flex-col md:flex-row lg:items-center mt-4 md:w-9/12 lg:w-full  md:mt-6 lg:mt-8'>
            <div className=' md:w-9/12 mx-auto lg:mx-0 lg:w-2/6 md:pl-5'>
              <h2 className='font-bold text-xl lg:text-3xl text-midnightBlue whitespace-nowrap'>
                {t("sales_support")}
              </h2>
            </div>
            <div className='flex md:ml-16 md:ml-auto md:space-x-2 mt-2 md:mt-0'>
              <img src={saudhiFlag} alt='' className='w-8' />
              <p className='text-custome_gray whitespace-nowrap'>
                +966 11 288 03 22
              </p>
            </div>
          </div>
        </div> */}
        <hr className='mt-4 md:mt-8' />
        <div className='flex justify-between mt-4 text-custome_gray opacity-50 mx-4 items-center'>
          <small className='pt-1'>
            {/* <a href='https://aptagrim.com' target='_black'> */}
            Smart Hire © {new Date().getFullYear()}
            {/* </a> */}
          </small>
          <span>
            <small>{t('footer_terms_conditions')} - </small>
            <Link
              to='/privacy-policy'
              target='_blank'
              rel='noopener noreferrer'
            >
              <small className='cursor-pointer'>
                {t('footer_privacy_policy')}
              </small>
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
